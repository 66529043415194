import { createMuiTheme } from '@material-ui/core/styles'

import EbGarmondWoff2 from './fonts/eb-garamond-regular.woff2'
import OswaldWoff2 from './fonts/oswald-regular.woff2'
import BalsamiqSans from './fonts/BalsamiqSans-Regular.ttf'

const oswald = {
  fontFamily: 'Oswald',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Oswald'),
    local('Oswald-Regular'),
    url(${OswaldWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}

const ebgaramond = {
  fontFamily: 'EbGaramond',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('EbGaramond'),
    local('EbGaramond-Regular'),
    url(${EbGarmondWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}

const balsamiq = {
  fontFamily: 'Balsamiq Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Balsamiq Sans'),
    url(${BalsamiqSans}) format('woff2')
  `
}

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      /* h1: { fontFamily: "Oswald" }, */
      h2: {}
    },
    MuiButton: {
      root: {
        fontSize: '18px',
        borderRadius: 0,
        textTransform: 'capitalize'
      }
    },
    MuiTabs: {
      scrollButtons: {
        color: 'white'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [ebgaramond, oswald, balsamiq],
        html: { scrollBehavior: 'smooth' },
        body: {
          backgroundColor: '#ffffff'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#f3a12e',
      black: '#000000',
      white: '#ffffff',
      gold: '#bb8b08',
      red: '#702225'
    }
  },
  typography: {
    fontFamily: 'EbGaramond, Arial, Oswald',

    h2: {
      fontFamily: 'Oswald',

      fontSize: '55px'
    },
    h3: {
      fontFamily: 'Oswald',
      fontSize: '45px'
    },

    button: {
      boxShadow: 'none',
      fontFamily: 'Poppins, sans-serif'
    }
  }
})

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  window.theme = theme
}

export default theme
