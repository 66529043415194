import { Box, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import Food from "../../images/malandra-food.jpg";
import Logo from "../../images/malandra-woman-logo.png";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  mainContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    flexShrink: 0,
  },
  imageContainer: {
    // display: "flex",

    position: "relative",

    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    "& h2": {
      textAlign: "center",
      color: "#f3a12e",
      fontSize: 25,
      margin: 0,
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "fill",
    },
  },
  bigImageContainer: {
    width: "100%",
    flexShrink: 0,
    display: "flex",
    alignItems: "flexEnd",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "fill",
    },
  },
  button: {
    fontSize: "20px",
    margin: "60px auto",
    fontFamily: "Poppins, sans-serif",
    position: "absolute",
    left: "20%",
    top: "35%",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      top: "30%",
      left: "5%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      top: "35%",
      left: "20%",
    },
    [theme.breakpoints.between("md", "lg")]: {
      top: "30%",
      left: "20%",
    },
  },
}));

function Hero(props) {
  const classes = useStyles();
  const { id } = props;
  return (
    <Container className={classes.flexContainer} id={id} maxWidth="xl">
      <Grid container className={classes.mainContainer}>
        <Grid item className={classes.container} md={6} sm={12}>
          <Box
            className={classes.imageContainer}
            style={{ textAlign: "center" }}
          >
            <h2>
              Montag bis Freitag <br />
              11:30 bis 14:00 Uhr <br />
              18:00 bis 22:00 Uhr <br />
              <br />
              Samstag und Sonntag Ruhetag
            </h2>
            <img
              alt="logo"
              src={Logo}
              style={{ marginBottom: "5px", width: "60%" }}
            />
          </Box>
        </Grid>

        <Grid item className={classes.bigImageContainer} md={6} sm={12}>
          <img alt="food" src={Food} style={{ objectFit: "cover" }} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hero;

/* import React from "react";
import { Box, Container, Button, Grid, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import Food from "../../images/malandra-food.jpg";
import Logo from "../../images/malandra-woman-logo.png";

const useStyles = makeStyles(theme => ({
  flexContainer: {
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },
  mainContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    flexShrink: 0
  },
  imageContainer: {
    // display: "flex",

    position: "relative",

    [theme.breakpoints.down("sm")]: {
      padding: 10
    },

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "fill"
    }
  },
  bigImageContainer: {
    width: "100%",
    flexShrink: 0,
    display: "flex",
    alignItems: "flexEnd",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "fill"
    }
  },
  button: {
    fontSize: "20px",
    margin: "0px auto",
    fontFamily: "Poppins, sans-serif",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main
    }
  },
  buttonContainer: {
    position: "absolute",
    border: "2px solid red",
    left: "7%",
    top: "13%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 0,

    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      top: "15%",
      left: "5%"
    },
    [theme.breakpoints.between("sm", "md")]: {
      top: "35%",
      left: "20%"
    },
    [theme.breakpoints.between("md", "lg")]: {
      top: "30%",
      left: "20%"
    }
  },
  announcement: {
    fontSize: "35px",
    width: "70%",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      width: "100%"
    }
  }
}));

function Hero(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" id={props.id} className={classes.flexContainer}>
      <Grid container className={classes.mainContainer}>
        <Grid item item sm={12} md={6} className={classes.container}>
          <Box className={classes.imageContainer}>
            <img
              src={Logo}
              style={{ marginBottom: "20px", width: "95%" }}
            ></img>
            <Box className={classes.buttonContainer}>
              <Typography
                color="primary"
                paragraph
                className={classes.announcement}
              >
                Ab 17.12.2019 geöffnet!
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classes.button}
              >
                <a href="/#speisen" className={classes.listItemLink}>
                  Tageskarte
                </a>
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item sm={12} md={6} className={classes.bigImageContainer}>
          <img src={Food} style={{ objectFit: "cover" }}></img>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hero;
 */
