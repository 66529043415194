import './App.css'

import { Router } from '@reach/router'
import React from 'react'

import About from './components/About'
import Award from './components/Award'
import ContactInfo from './components/ContactInfo'
import Datenschutz from './components/Datenschutz'
import Footer from './components/Footer'
import Gallery from './components/Gallery'
import Hero from './components/Hero'
import Impressum from './components/Impressum'
import LocationMap from './components/LocationMap'
import Menu from './components/Menu'
import Navigation from './components/Navigation'
import Newsletter from './components/Newsletter'
import PhoneReservation from './components/PhoneReservation'
// import Popup from './components/Popup'

function NewsletterPage() {
  return (
    <>
      <Newsletter />
    </>
  )
}

function DatenschutzPage() {
  return (
    <>
      <Navigation path="navigation" />
      <Datenschutz />
      <Footer />
    </>
  )
}

function ImpressumPage() {
  return (
    <>
      <Navigation path="navigation" />
      <Impressum />
      <Footer />
    </>
  )
}

function HomePage() {
  return (
    <>
      {/*  <Popup></Popup> */}
      <Navigation path="navigation" />
      <Hero id="home" />
      <About id="about" />
      <Gallery id="galerie" />
      <PhoneReservation id="reservierung" />
      <Menu id="speisen" />

      <LocationMap />
      <Award id="award" />
      <ContactInfo id="kontakt" />

      <Footer />
    </>
  )
}

function App() {
  return (
    <>
      <Router>
        <HomePage path="/" />
        <NewsletterPage path="/newsletter" />
        <DatenschutzPage path="/datenschutz" />
        <ImpressumPage path="/impressum" />
      </Router>
    </>
  )
}

export default App
