import { Box, Container, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import BodyRenderer from '../BodyRenderer'

const contentful = require('contentful')

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.primary.black,
    paddingTop: 25
  },

  heading: {
    lineHeight: '80px',
    textTransform: 'uppercase',
    color: theme.palette.primary.white,
    margin: 15,

    [theme.breakpoints.down('md')]: {
      fontSize: 40
    }
  },
  menuBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabsContainer: {
    display: 'flex',
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  shortMenuDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  shortMenuDescription: {
    textAlign: 'center',
    fontFamily: 'EB Garmond',
    fontSize: 28,
    fontWeight: 500,
    maxWidth: 900,
    margin: 30,
    lineHeight: '35px'
  },
  dish: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 500,
    margin: 30
  },
  dishName: {
    textAlign: 'center',
    fontFamily: 'EB Garmond',
    fontSize: 25,

    marginBottom: 0,
    lineHeight: '35px',
    color: theme.palette.primary.main
  },
  dishDescription: {
    '& p': {
      textAlign: 'center',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: 20,
      color: '#929292',
      marginBottom: 0
    }
  },
  dishPrice: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 20,

    marginBottom: 0
  },
  tabs: {},
  tab: {
    color: 'white',
    fontSize: 20,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  starsContainer: {
    display: 'flex',
    width: 100,

    justifyContent: 'space-around',
    '& span': {
      fontWeight: 700,
      fontSize: 14
    }
  },
  zutaten: {
    height: '500px',
    border: '2px solid red',
    width: '300px'
  }
}))

function Menu(props) {
  const classes = useStyles()
  const [data, setData] = useState([])
  const [value, setValue] = useState(0)
  const { id } = props

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    const client = contentful.createClient({
      /* space: process.env.REACT_APP_SPACE_ID,
      accessToken: process.env.REACT_APP_ACCESS_TOKEN */

      space: '2nhn12fsc822',
      accessToken: '3SQv9P2LQ-DL36aquyTe4tP_FJDezamxzMJyPoGU1HM'
      /* "2nhn12fsc822",
    "3SQv9P2LQ-DL36aquyTe4tP_FJDezamxzMJyPoGU1HM" */
    })
    client
      .getEntries({ content_type: 'speisekarten', include: 2 })
      .then((response) => setData(response.items))
      .catch(console.error)
  }, [])

  return (
    <>
      <Box className={classes.container} id={id}>
        <Typography className={classes.heading} variant="h2">
          Unsere Speisen
        </Typography>
      </Box>

      <Box>
        <Box className={classes.tabsContainer}>
          <Container
            maxWidth="md"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Tabs
              aria-label="scrollable auto tabs example"
              className={classes.tabs}
              indicatorColor="primary"
              scrollButtons="on"
              textColor="primary"
              value={value}
              variant="scrollable"
              onChange={handleChange}
            >
              {data.map((item) =>
                item.fields.speisekarte.map((menu) => {
                  if (!menu.fields) return null
                  return (
                    <Tab
                      key={menu.sys.id}
                      className={classes.tab}
                      label={menu.fields.name}
                    />
                  )
                })
              )}
            </Tabs>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="xl">
        {data.map((item) =>
          item.fields.speisekarte
            .filter((element) => element.fields) // entfernt alle undefinierten oder ausgeblendeten Menues
            .map((menu, idx) => {
              if (!menu.fields) return null
              return (
                <Box key={menu.sys.id} display={idx !== value && 'none'}>
                  <Container
                    className={classes.shortMenuDescriptionContainer}
                    justify="center"
                    maxWidth="lg"
                  >
                    {menu.fields.kurzeMenbeschreibung && (
                      <Typography
                        paragraph
                        className={classes.shortMenuDescription}
                      >
                        {menu.fields.kurzeMenbeschreibung}
                      </Typography>
                    )}
                  </Container>

                  {menu.fields.gericht.map((dish) => {
                    return (
                      <Box className={classes.menuBox}>
                        <Box className={classes.dish}>
                          <Typography paragraph className={classes.dishName}>
                            {dish.fields.tellername}
                          </Typography>

                          {dish.fields.gerichtZutaten && (
                            <Box className={classes.dishDescription}>
                              <BodyRenderer json={dish.fields.gerichtZutaten} />
                            </Box>
                          )}

                          {dish.fields.preis && (
                            <Typography paragraph className={classes.dishPrice}>
                              <span>€</span>
                              {dish.fields.preis.toFixed(2)}
                            </Typography>
                          )}
                        </Box>
                        <Box className={classes.starsContainer}>
                          <span>✻</span>
                          <span>✻</span>
                          <span>✻</span>
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              )
            })
        )}
      </Container>
    </>
  )
}

export default Menu
