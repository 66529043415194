import { Box, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Chef from '../../images/chef.jpg'
// import Cheff from '../../images/cheff.jpg'
// import CheffCooking from '../../images/OsteriaMalandra3.jpg'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 0
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: 0
  },
  imageContainer: {
    width: '85%',
    height: '87%',
    overflow: 'hidden',
    '& img': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: '0px 50px'
    }
  },
  heading: {
    fontSize: 40,
    lineHeight: '55px',
    fontWeight: 300,
    textTransform: 'initial',
    color: theme.palette.primary.main,
    textAlign: 'left',
    margin: '40px 0px',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '40px',
      fontSize: 35,
      margin: '40px 0px'
    }
  },
  paragraph: {
    fontSize: 25,
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {}
  },
  textGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  button: {
    fontSize: 28,
    fontWeight: '400',
    borderColor: theme.palette.primary.black,
    marginBottom: 30
  },
  restaurant: {
    maxWidth: '100%',
    '& img': {
      maxWidth: '100%'
    }
  }
}))

function About(props) {
  const classes = useStyles()
  const { id } = props
  return (
    <Container className={classes.mainContainer} id={id} maxWidth="xl">
      <Grid container>
        <Grid item className={classes.container} md={6} xs={12}>
          <Box className={classes.imageContainer}>
            <div>
              <img alt="Koch" src={Chef} />
            </div>
          </Box>
        </Grid>
        <Grid item className={classes.textGrid} md={6} xs={12}>
          <Box className={classes.textContainer}>
            <Typography className={classes.heading} variant="h1">
              Mehr Raum für kleine und große Gäste.
            </Typography>
            <Typography paragraph className={classes.paragraph}>
              Für den Bürgersaal als neue Location gab und gibt es naturgemäß
              mehrere gute Gründe. Ein ganz gewichtiger: Die neue Adresse bietet
              schlicht und einfach mehr Raum und mehr Möglichkeiten für die
              Feste und Feiern, die das Leben so lebens- und liebenswert machen.
              Dann ist da natürlich die bevorzugte zentrale Lage im Herzen von
              Ismaning zwischen Schlosspark, Johann-Andreas-Schmeller Realschule
              und Bahnhof, die kurze Wege zu Fuß und mit dem Radl verspricht.
              Aber auch all jene Gäste aus Nah und Fern, die mit dem PKW kommen,
              dürfen sich in der unmittelbaren Umgebung des Bürgersaals über
              eine Vielzahl von Parkmöglichkeiten freuen. Ein klares Plus
              gegenüber dem bisherigen Standort.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default About
