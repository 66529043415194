import React from "react";
import { Container, Box, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "60px 0px"
  },
  heading: {
    color: theme.palette.primary.white,
    textAlign: "center",
    fontWeight: "light",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: 40
    }
  },
  paragraph: {
    fontSize: 20,
    marginBottom: 0,
    marginTop: 30,
    textTransform: "uppercase",
    color: theme.palette.primary.white,
    lineHeight: "40px",
    fontFamily: "Open Sans",
    fontWeight: "300",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px",
      fontSize: "18px"
    },
    "& span": {
      fontWeight: "bold",
      margin: "0px 10px",
      color: theme.palette.primary.white
    },
    "& a": {
      textDecoration: "none"
    }
  }
}));

function PhoneReservation(props) {
  const classes = useStyles();
  return (
    <Box className={classes.container} id={props.id}>
      <Container maxWidth="md">
        <Typography variant="h2" className={classes.heading}>
          Reservierung
        </Typography>
        <Typography paragraph className={classes.paragraph}>
          Bitte rufen Sie uns für Ihre Reservierung unter
          <a href="tel:089 99 62 86 95">
            <span>089 99 62 86 95</span>
          </a>
          an. Schon bald können Sie Ihre Reservierung auch ganz bequem online
          tätigen.
        </Typography>
      </Container>
    </Box>
  );
}

export default PhoneReservation;
