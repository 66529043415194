import { Box, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '20px 0px'
  },
  infoContainer: {
    display: 'flex',

    justifyContent: 'center',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  infoBox: {
    width: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    padding: 20,
    margin: '0px 20px',

    '& p': {
      fontSize: '22px',
      textAlign: 'left',
      lineHeight: '20px'
    },
    '& a': {
      textDecoration: 'none !important'
    }
  }
}))

function ContactInfo(props) {
  const { id } = props
  const classes = useStyles()
  return (
    <Container id={id} maxWidth="lg">
      <Grid container className={classes.container} justify="center">
        <Grid item justify="center" md={10} xs={12}>
          <Grid container className={classes.infoGrid}>
            <Box className={classes.infoContainer}>
              <Box className={classes.infoBox}>
                <a
                  className="sq_center "
                  href="https://de.restaurantguru.com/Malandra-Osteria-Ismaning"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <link
                    href="https://awards.infcdn.net/circle_v2.css"
                    rel="stylesheet"
                  />
                  <div
                    className=""
                    id="circle_v2"
                    onClick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.sq_center').href);return 0;}"
                  >
                    {' '}
                    <div className="arc-heading ">
                      {' '}
                      <svg
                        //   xmlns="http://www.w3.org/2000/svg"
                        //   xmlns:xlink="http://www.w3.org/1999/xlink"
                        height="160px"
                        viewBox="0 0 160 160"
                        width="160px"
                      >
                        {' '}
                        <defs>
                          {' '}
                          <path
                            d="M 30 80 a 50 50 0 1 1 100 0"
                            id="heading-arc"
                          />{' '}
                        </defs>{' '}
                        <text
                          className="arc-heading__heading "
                          fill="#000"
                          textAnchor="middle"
                        >
                          {' '}
                          <textPath startOffset="50%" xlinkHref="#heading-arc">
                            Empfohlen
                          </textPath>{' '}
                        </text>{' '}
                      </svg>{' '}
                    </div>{' '}
                    <div className="sq_year">2022</div>{' '}
                    <a
                      className="sq_center "
                      href="https://de.restaurantguru.com/Malandra-Osteria-Ismaning"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Osteria Malandra
                    </a>{' '}
                    <div className="arc-heading arc-heading__bottom">
                      {' '}
                      <svg
                        height="120px"
                        viewBox="0 0 120 120"
                        width="120px"
                        //   xmlns="http://www.w3.org/2000/svg"
                        //   xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        {' '}
                        <defs>
                          {' '}
                          <path
                            d="M 12 60 a 48 48 0 0 0 96 0"
                            id="subheading-arc"
                          />{' '}
                        </defs>{' '}
                        <text
                          className="arc-heading__subheading"
                          fill="#000"
                          textAnchor="middle"
                        >
                          {' '}
                          <textPath
                            startOffset="50%"
                            xlinkHref="#subheading-arc"
                          >
                            <a
                              href="https://restaurantguru.com"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Restaurant Guru
                            </a>
                          </textPath>{' '}
                        </text>{' '}
                      </svg>{' '}
                    </div>
                  </div>
                </a>
              </Box>
              <Box className={classes.infoBox}>
                {/* <style>@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@1,700&display=swap');</style> */}
                <a
                  href="https://www.sluurpy.de/ismaning/restaurant/1735500/osteria-malandra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    id="sluurpytag_22_l"
                    style={{
                    //   all: 'unset',
                    //   all: 'initial',
                      cursor: 'pointer',
                      fontFamily: 'Balsamiq Sans, cursive',
                      color: '#707070',
                      boxSizing: 'border-box',
                      display: 'block',
                      overflow: 'hidden',
                      clear: 'both',
                      width: '155px',
                      height: '155px',
                      border: '5px solid #ec7205',
                      borderRadius: '100%',
                      textAlign: 'center',
                      fontWeight: 'bolder'
                    }}
                    onClick="if(event.target.nodeName.toLowerCase() !='a'){top.location.href='https://www.sluurpy.de/ismaning/restaurant/1735500/osteria-malandra';}"
                  >
                    <div
                      style={{
                        zIindex: 2,
                        display: 'block',
                        height: '62.5px',
                        padding: '2px',
                        backgroundColor: 'white'
                      }}
                    >
                      <img
                        src="https://www.sluurpy.it/certificato/uu.png"
                        style={{
                          all: 'initial',
                          verticalAlign: 'middle',
                          borderStyle: 'none',
                          width: '30px',
                          height: '30px'
                        }}
                        alt="sluurpy-cert"
                      />
                      <div style={{ display: 'block', fontSize: '12px' }}>
                        <a
                          href="https://www.sluurpy.com"
                          style={{ color: '#707070', cursor: 'pointer' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SLUURPY
                        </a>{' '}
                        2022
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'block',
                        fontSize: '12px',
                        height: '20px',
                        lineHeight: '20px',
                        backgroundColor: '#ec7205',
                        color: 'white'
                      }}
                    >
                      CONSIGLIATO
                    </div>
                    <div
                      style={{
                        display: 'block',
                        height: '62.5px',
                        backgroundColor: 'white',
                        paddingTop: '4px',
                        fontWeight: 'bold'
                      }}
                    >
                      <a
                        href="https://www.sluurpy.de/ismaning/restaurant/1735500/osteria-malandra"
                        rel="noopener noreferrer"
                        style={{ color: '#707070', cursor: 'pointer' }}
                        target="_blank"
                      >
                        Osteria Malandra
                      </a>
                    </div>
                  </div>
                </a>
              </Box>
              <Box className={classes.infoBox}>
                  <img
                    src="urkunde.png"
                    style={{
                      all: 'initial',
                      verticalAlign: 'middle',
                      borderStyle: 'none',
                      width: '300px'
                    }}
                    alt="malandra-cert"
                  />
              </Box>
              <Box className={classes.infoBox}>
                <Typography paragraph>
                  Empfohlen von Restaurant Guru & Sluurpy!
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContactInfo
