import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    [theme.breakpoints.down('md')]: {},
    padding: '40px 0px'
  }
}))

function LocationMap() {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <iframe
        allowFullScreen
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2657.9105707708122!2d11.674345616344162!3d48.22759657923052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e7339b5c34739%3A0x4f0347e96adbbd5e!2sAn%20der%20Torfbahn%201%2C%2085737%20Ismaning!5e0!3m2!1sde!2sde!4v1574428230268!5m2!1sde!2sde"
        style={{ width: '100%', height: '500px', border: 'none' }}
        title="map"
      />
    </Box>
  )
}

export default LocationMap
