import { Box, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '20px 0px'
  },
  infoContainer: {
    display: 'flex',

    justifyContent: 'center',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  infoGrid: {
    display: 'flex',

    justifyContent: 'space-between',
    margin: '30px 0px'
  },
  infoBox: {
    width: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    padding: 20,
    margin: '0xp 20px',

    '& p': {
      fontSize: '22px',
      textAlign: 'left',
      lineHeight: '20px'
    }
  },

  heading: {
    lineHeight: '80px',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: 55,
    margin: '30, 0px',
    textAlign: 'center'
  },
  middleBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  endBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function ContactInfo(props) {
  const { id } = props
  const classes = useStyles()
  return (
    <Container id={id} maxWidth="lg">
      <Grid container className={classes.container} justify="center">
        <Grid item justify="center" md={10} xs={12}>
          <Typography className={classes.heading} variant="h2">
            Kontakt
          </Typography>

          <Grid container className={classes.infoGrid}>
            <Box className={classes.infoContainer}>
              <Box className={classes.infoBox}>
                <Typography paragraph> An der Torfbahn 1</Typography>
                <Typography paragraph> 85737 Ismaning</Typography>
                <Typography paragraph> Deutschland</Typography>
              </Box>

              <Box className={classes.infoBox}>
                <Typography paragraph> Fon 089 99 62 86 95</Typography>
                <Typography paragraph> info@osteria-malandra.de</Typography>
              </Box>

              <Box className={classes.infoBox}>
                <Typography paragraph>
                  Montag bis Freitag
                </Typography>
                <Typography>11:30 bis 14:00 Uhr</Typography>
                <Typography>18:00 bis 22:00 Uhr</Typography>
                <Typography>Samstag und Sonntag Ruhetag</Typography>
                {/* <Typography paragraph> 11:30 bis 15:00 Uhr</Typography>
                <Typography paragraph>Speisen und Getränke zum mitnehmen</Typography>
                <Typography paragraph> 18:00 bis 21:00 Uhr </Typography> */}
                {/* <Typography paragraph> Sa. &amp; So. Ruhetag</Typography> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContactInfo
