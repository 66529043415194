import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@reach/router'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.primary.black,
    padding: '20px 0px',
    justifyContent: 'center'
  },
  menuList: {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  listItem: {
    color: theme.palette.primary.gold,
    textTransform: 'uppercase',
    padding: '5px 15px',
    letterSpacing: '3px',
    fontSize: '18px',
    fontFamily: 'Poppins, sans-serif',
    cursor: 'pointer',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 5,

      fontSize: '14px'
    }
  },
  listItemLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  subFooterContainer: {
    display: 'flex',

    justifyContent: 'center'
  },
  subFooter: {
    backgroundColor: theme.palette.primary.white,
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 7,
      alignItems: 'flex-start'
    }
  },
  link: {
    color: '#c8382f',
    cursor: 'pointer',
    margin: 5,
    fontSize: '18px',

    fontFamily: 'Poppins, sans-serif',
    borderBottom: '1px solid white',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: 5,

      fontSize: '14px'
    }
  }
}))

function Footer() {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.container}>
        <ul className={classes.menuList}>
          <li className={classes.listItem}>
            <a className={classes.listItemLink} href="/">
              Home
            </a>
          </li>
          <li className={classes.listItem}>
            <a className={classes.listItemLink} href="/#about">
              Über Uns
            </a>
          </li>
          <li className={classes.listItem}>
            <a className={classes.listItemLink} href="/#speisen">
              Speisen
            </a>
          </li>
          <li className={classes.listItem}>
            <a className={classes.listItemLink} href="/#galerie">
              Galerie
            </a>
          </li>
          <li className={classes.listItem}>
            <a className={classes.listItemLink} href="/#kontakt">
              Kontakt
            </a>
          </li>
          <li className={classes.listItem}>
            <a className={classes.listItemLink} href="/#reservierung">
              Reservierung
            </a>
          </li>
        </ul>
      </Box>
      <Box className={classes.subFooterContainer}>
        <ul className={classes.subFooter}>
          <li className={classes.listItem}>
            <Link className={classes.link} to="/impressum">
              Impressum
            </Link>
          </li>
          <li className={classes.listItem}>
            <Link className={classes.link} to="/datenschutz">
              Datenschutz
            </Link>
          </li>
        </ul>
      </Box>
    </>
  )
}

export default Footer
