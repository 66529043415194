import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import styled from 'styled-components'

// import OsteriaMalandra1 from "../../images/OsteriaMalandra1.jpg";
// import OsteriaMalandra2 from "../../images/OsteriaMalandra2.jpg";
// import OsteriaMalandra3 from "../../images/OsteriaMalandra3.jpg";
import slide1 from '../../images/slide1.jpeg'
import slide2 from '../../images/slide2.jpeg'

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`

// const Slide = styled.div`
//   width: 100%;
//   height: 700px;
//   background-image: ${(props) => `url(${props.background})`};
//   background-position: top;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-origin: initial;
//   background-clip: initial;
//   @media (max-width: 600px) {
//     background-size: contain;
//   }
// `

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    width: '100%',
    maxHeight: '700px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '500px'
    },

    '& div': {
      display: 'flex',
      width: '100%',
      maxHeight: '700px',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '500px'
      }
    }
  }
}))

function Gallery(props) {
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews)
  const classes = useStyles()
  const { id } = props
  return (
    <Container
      id={id}
      maxWidth="xl"
      style={{
        display: 'flex',

        alignItems: 'flexEnd',
        padding: 0
      }}
    >
      <AutoPlaySwipeableViews
        className={classes.sliderContainer}
        interval={6000}
      >
        {/* <Slide background={OsteriaMalandra1}></Slide>
        <Slide background={OsteriaMalandra2}></Slide> */}

        <Image src={slide1} />
        <Image src={slide2} />
      </AutoPlaySwipeableViews>
    </Container>
  )
}

export default Gallery
