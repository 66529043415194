/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Box, Container, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import styled from 'styled-components'

import BurgerMenu from '../../images/burger-menu.svg'
import CloseMenu from '../../images/close.png'
import Logo from '../../images/logo.png'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.white,
    padding: '20px 0',
    flexWrap: 'wrap',
    alignItems: 'center',

    [theme.breakpoints.up('lg')]: {},
    [theme.breakpoints.between('md', 'lg')]: {},
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    }
  },
  navBar: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      height: 50,
      margin: 10
    }
  },
  burgerMenu: {
    width: 40,
    position: 'absolute',
    top: 15,
    right: 10
  },
  menuList: {
    margin: 0,
    padding: 0,

    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.primary.red,
      padding: '40px 0px',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 2000
    }
  },
  listItem: {
    color: theme.palette.primary.red,
    textTransform: 'uppercase',
    padding: '10px',
    letterSpacing: '1px',
    fontSize: '20px',
    margin: '15px',

    cursor: 'pointer',
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.primary.white,
      textAlign: 'center',
      fontSize: '16px',
      padding: '5px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      margin: '10px'
    }
  },
  listItemLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  menuTitle: {
    color: theme.palette.primary.white,
    padding: 10
    /*  marginTop: "10px" */
  }
}))

const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-direction: column;
    padding-left: 0;
    background-color: #ebebeb;
    ${({ open }) => (open ? '' : 'display: none;')}
  }
`

function Navigation() {
  const classes = useStyles()
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const ToggleMobileNav = () => {
    setIsMobileNavOpen((prevIsMobileNavOpen) => !prevIsMobileNavOpen)
  }

  return (
    <Container className={classes.mainContainer} maxWidth="xl">
      <Box className={classes.header}>
        <a href="/">
          <Box>
            <img alt="Logo" className={classes.logo} src={Logo} />
          </Box>
        </a>
        <Box className={classes.navBar}>
          <Hidden mdUp>
            {isMobileNavOpen ? (
              <img
                alt="BurgerMenu"
                className={classes.burgerMenu}
                src={CloseMenu}
                onClick={ToggleMobileNav}
              />
            ) : (
              <img
                alt="BurgerMenu"
                className={classes.burgerMenu}
                src={BurgerMenu}
                onClick={ToggleMobileNav}
              />
            )}
          </Hidden>
          <List
            className={classes.menuList}
            open={isMobileNavOpen}
            ToggleMobileNav={ToggleMobileNav}
          >
            <Hidden mdUp>
              <img
                alt="BurgerMenu"
                className={classes.burgerMenu}
                src={CloseMenu}
                style={{ height: '20px', width: '20px', margin: '20px' }}
                onClick={ToggleMobileNav}
              />
            </Hidden>
            <li className={classes.listItem}>
              <a className={classes.listItemLink} href="/">
                Home
              </a>
            </li>
            <li className={classes.listItem}>
              <a className={classes.listItemLink} href="/#about">
                Über uns
              </a>
            </li>
            <li className={classes.listItem}>
              <a className={classes.listItemLink} href="/#speisen">
                Speisen
              </a>
            </li>
            <li className={classes.listItem}>
              <a className={classes.listItemLink} href="/#galerie">
                Galerie
              </a>
            </li>
            <li className={classes.listItem}>
              <a className={classes.listItemLink} href="/#kontakt">
                Kontakt
              </a>
            </li>
            <li
              className={classes.listItem}
              style={{ border: '1px solid #702225' }}
            >
              <a className={classes.listItemLink} href="/#reservierung">
                Reservierung
              </a>
            </li>
          </List>
        </Box>
      </Box>
    </Container>
  )
}

export default Navigation
