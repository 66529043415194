import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

import { db } from '../../firebase'
import Logo from '../../images/newsletter-logo.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',

    alignContent: 'center',
    padding: 20
  },
  baseContainer: {
    /* backgroundImage: `url(${Logo})`, */
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    position: 'relative',
    padding: 80,

    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Poppins sans-serif',
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  },
  formContainer: {
    backgroundColor: theme.palette.primary.white,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 40,
    margin: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      margin: 0
    }
  },
  logoContainer: {
    padding: 20,
    '& img': {
      height: '120px',
      [theme.breakpoints.down('sm')]: {
        height: '80px'
      }
    }
  },
  paragraphContainer: {
    width: '70%',
    padding: 20,
    margin: '50px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0
    }
  },
  paragraph: {
    fontFamily: 'EB Garmond',
    fontSize: 25,
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  textField: {},
  label: {
    fontSize: 18,
    fontFamily: 'Poppins, sans-serif',
    margin: '10px 0px',
    '&::placeholder': {
      color: 'black',
      opacity: 1,
      fontWeight: 200,
      letterSpacing: '2px'
    }
  },
  checkBoxContainer: {
    margin: '10px 0px'
  },
  button: {
    padding: '10px 50px',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'uppercase',
    fontWeight: 400,
    letterSpacing: '3px',
    margin: '60px auto',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 30px',
      margin: '20px auto'
    }
  },
  form: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}))

function Newsletter() {
  const classes = useStyles()

  // const [isChecked, setIsChecked] = useState(false)
  const [name, setName] = useState('')
  const [vorname, setVorname] = useState('')
  const [email, setEmail] = useState('')
  const [addresse, setAddresse] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  /* 
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false); */
  /* const [customer, setCustomer] = useState({
    name: "",
    vorname: "",
    addresse: "",
    email: ""
  }); */

  /*  function handleFormSubmit(e) {
    e.preventDefault();
    const customersRef = firebase.database().ref("Customers");
    const customer = {
      name: name,
      vorname: vorname,
      email: email,
      addresse: addresse
    };
    customersRef.push(customer);
    setName("");
    setVorname("");
    setEmail("");
    setAddresse("");
    console.log("customer added successfuly");
  }
 */

  function handleFormSubmit(e) {
    e.preventDefault()
    db.collection('Customer').add({
      name,
      vorname,
      email,
      addresse
    })
    setName('')
    setVorname('')
    setEmail('')
    setAddresse('')
    setIsSubmitted(true)
  }

  function createHandleChange(setState) {
    return (event) => setState(event.target.value)
  }
  return (
    <Container className={classes.container} maxWidth="md">
      <Box className={classes.baseContainer}>
        <Box className={classes.formContainer}>
          <Box className={classes.logoContainer}>
            <img alt="Logo" src={Logo} />
          </Box>
          <Box className={classes.paragraphContainer}>
            {isSubmitted ? (
              <Typography paragraph className={classes.paragraph}>
                Vielen Dank für Ihre Daten, ab sofort werden Sie über die
                Eröffnung informiert!
              </Typography>
            ) : (
              <Typography paragraph className={classes.paragraph}>
                Bitte geben Sie unten Ihre Daten an. Wir möchten Sie über unsere
                Aktionen and Veranstaltugen auf dem Laufenden halten!
              </Typography>
            )}
          </Box>
          <Grid container display="flex" justify="center">
            <Grid className={classes.inputContainer} xs={10}>
              <form className={classes.form} onSubmit={handleFormSubmit}>
                <TextField
                  fullWidth
                  required
                  className={classes.textField}
                  id="standard-full-width"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: {
                      input: classes.label
                    }
                  }}
                  margin="normal"
                  name="name"
                  placeholder="Name"
                  style={{ margin: 8 }}
                  value={name}
                  onChange={createHandleChange(setName)}
                />
                <TextField
                  fullWidth
                  required
                  id="standard-full-width"
                  InputLabelProps={{
                    shrink: true,
                    color: 'black'
                  }}
                  InputProps={{
                    classes: {
                      input: classes.label
                    }
                  }}
                  margin="normal"
                  name="vorname"
                  placeholder="Vorname"
                  style={{ margin: 8 }}
                  value={vorname}
                  onChange={createHandleChange(setVorname)}
                />
                <TextField
                  fullWidth
                  id="standard-full-width"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: {
                      input: classes.label
                    }
                  }}
                  margin="normal"
                  name="addresse"
                  placeholder="Adresse"
                  style={{ margin: 8 }}
                  value={addresse}
                  onChange={createHandleChange(setAddresse)}
                />
                <TextField
                  fullWidth
                  required
                  id="standard-full-width"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: {
                      input: classes.label
                    }
                  }}
                  margin="normal"
                  name="email"
                  placeholder="E-mail Adresse"
                  style={{ margin: 8 }}
                  value={email}
                  onChange={createHandleChange(setEmail)}
                />

                <Button
                  className={classes.button}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Absenden
                </Button>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default Newsletter
