const config = {
  apiKey: "AIzaSyAguYIIAIk5JXs1CL06S-viTkcTrZCxbCI",
  authDomain: "malandra-osteria-newsletter.firebaseapp.com",
  databaseURL: "https://malandra-osteria-newsletter.firebaseio.com",
  projectId: "malandra-osteria-newsletter",
  storageBucket: "malandra-osteria-newsletter.appspot.com",
  messagingSenderId: "450168201397",
  appId: "1:450168201397:web:27c05576b8d20630a55efc"
};

export default config;
